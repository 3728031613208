import { Cliente } from '@velits/endtest-lib';

const { hostname } = window.location;
const emailThiago = 'thiago.lacerda@endtest.com.br';
const assinatura = `
  <table>
    <tr>
      <td>
        <div style="color: #213e8d;"><strong>EndTest Inspeções Técnicas</strong></div>
        <div>
          <span style="font-family: 'Wingdings 2'">'</span>
          &nbsp;
          <a href="tel:+55 31 36223001">(31) 3622-3001</a>
        </div>
        <div>
          <span style="font-family: 'Wingdings'">*</span>
          &nbsp;
          <a href="mailto:contato@endtest.com.br">contato@endtest.com.br</a>
        </div>
        <div>
          <span style="font-family: 'Wingdings'">:</span>
          &nbsp;
          <a href="http://endtest.com.br/">http://endtest.com.br</a>
        </div>
      </td>
    </tr>
    <tr>
      <td><img src="http://endtest.com.br/img/logo-sm-white.png" alt="EndTest Inspeções"></td>
    </tr>
  </table>
`;

export function enviarEmailRelatório(
  sendEmail: (p: any) => Promise<any>,
  cliente: Cliente
) {
  // Solução para previnir clientes de e-mail como gmail de transformarem endereço em link
  const usuário = cliente.email?.replace(/([@.:])/g, '<span>$1</span>');

  const to = hostname === 'localhost' ? 'gleiderr@gmail.com' : cliente.email;
  const cc = hostname === 'localhost' ? undefined : emailThiago;

  return sendEmail({
    from: `EndTest Inspeções <contato@endtest.com.br>`,
    to,
    cc,
    subject: 'Relatório de Inspeção Técnica',
    html: `
      <div style="font-family: Arial, Helvetica, sans-serif">
        <p>Prezado(a) ${cliente.responsavel},</p>
        <p>Um novo relatório acaba de ser entregue através do <strong>Portal de Relatórios da EndTest</strong>.</p>

        <p>Para consultar seus relatórios acesse <a href="http://endtest.com.br/">endtest.com.br</a> > RELATÓRIOS.</p>

        <p>Seus dados de acesso são:</p>
        <ul>
          <li><strong>Usuário:</strong> ${usuário}</li>
          <li><strong>Senha:</strong> ${cliente.senha}</li>
        </ul>

        <p>Atenciosamente,</p>

        ${assinatura}
      </div>`,
  });
}

export function enviarEmailUpload(
  sendEmail: (p: any) => Promise<any>,
  cliente: Cliente
) {
  const to = hostname === 'localhost' ? 'gleiderr@gmail.com' : emailThiago;

  return sendEmail({
    from: `EndTest Inspeções <contato@endtest.com.br>`,
    to,
    subject: 'Upload - Relatório',
    html: `
      <div style="font-family: Arial, Helvetica, sans-serif">
        <p>Prezado(a),</p>
        <p>Upload de novo relatório acaba de ser realizado.</p>
        <p>O arquivo estará disponível ao cliente após ser entregue por você ou outro usuário administrador.</p>

        <p>Dados do cliente:</p>
        <ul>
          <li><strong>Cliente:</strong> ${cliente.nome}</li>
          <li><strong>Responsável:</strong> ${cliente.responsavel}</li>
        </ul>

        <p>Para consultar os relatórios acesse <a href="http://endtest.com.br/">endtest.com.br</a> > RELATÓRIOS.</p>

        <p>Atenciosamente,</p>

        ${assinatura}
      </div>`,
  });
}
