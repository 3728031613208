import { App as EndTest } from '@velits/endtest-lib';
import { Ambiente } from '@velits/velits-lib';
import React from 'react';
import './App.css';
import config from './firebaseConfig.json';
import { enviarEmailRelatório, enviarEmailUpload } from './myFunctions';

export function App() {
  return (
    <Ambiente
      firebaseConfig={config}
      functions={{ enviarEmailRelatório, enviarEmailUpload }}
    >
      <EndTest />
    </Ambiente>
  );
}
